import { ReactNode } from 'react';
import { cn } from '~/lib/utils';
import { FacebookSvgWhite } from './social-svgs/facebook-svg-white';
import { InstagramSvgWhite } from './social-svgs/instagram-svg-white';
import { Button } from './ui/button';
import { Typography } from './ui/typography';

export interface BrandCardProps {
  logoImgUrl: string;
  backgroundImgUrl: string;
  title: string;
  tagline: ReactNode;
  description: string;
  learnMoreUrl: string;
  websiteUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  className?: string;
}

export function BrandCard({
  logoImgUrl,
  backgroundImgUrl,
  title,
  tagline,
  description,
  learnMoreUrl,
  websiteUrl,
  facebookUrl,
  instagramUrl,
  className,
}: BrandCardProps) {
  return (
    <div className={cn('relative rounded-lg shadow-xl sm:aspect-[16/12]', className)}>
      <div
        className="absolute z-10 h-full w-full rounded-lg"
        style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%)' }}
      />
      <img className="absolute h-full w-full rounded-lg object-top" src={backgroundImgUrl} />
      <div className="flex h-full w-full flex-col items-center justify-between px-10 pb-10 pt-6">
        <img src={logoImgUrl} alt="Sukrit Wellness" className="relative z-20 h-20 w-20" />
        <div className="relative z-20 mt-3 flex w-full flex-col items-center justify-center text-center sm:mt-0">
          <Typography variant="h3" className="text-white">
            {title}
          </Typography>
          <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
            <Typography variant="tagline" className="inline text-white">
              {tagline}
            </Typography>
            <div className="flex flex-row gap-2">
              <a href={facebookUrl} target="_blank" rel="noreferrer">
                <FacebookSvgWhite />
              </a>
              <a href={instagramUrl} target="_blank" rel="noreferrer">
                <InstagramSvgWhite />
              </a>
            </div>
          </div>
          <Typography variant="p" className="mt-3 text-lg text-white md:w-[85%]">
            {description}
          </Typography>
          <div className="mt-4 flex justify-center space-x-4">
            <a href={learnMoreUrl}>
              <Button>View Website</Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
